import React from "react";

import {
  Box,
  Button,
  ColorMode,
  Heading,
  HStack,
  Inline,
  Paragraph,
  Text,
  VStack,
  Wrapper,
} from "components";

function ColorSwatch({ color }) {
  return (
    <Box width={128}>
      <Box pb="100%" bg={color} />
    </Box>
  );
}

function ColorGroup({ label, colors }) {
  return (
    <HStack space="layout.1" dividers={true}>
      <Heading children={label} size={100} width={128 - 16} />
      <Inline space="layout.1">
        {colors.map((color, i) => (
          <ColorSwatch color={color} key={label + i} />
        ))}
      </Inline>
    </HStack>
  );
}

function Colors() {
  return (
    <Wrapper py="layout.5" bg="background.0">
      <VStack space="layout.1" dividers={true}>
        <ColorGroup label="Text" colors={["text.0", "text.1", "text.2"]} />
        <ColorGroup
          label="Backgrounds"
          colors={["background.0", "background.1", "background.2"]}
        />
        <ColorGroup label="Border" colors={["border.0"]} />
        <ColorGroup label="Fills" colors={["fill.0", "fill.1"]} />
        <ColorGroup label="Tints" colors={["tint.teal", "tint.orange"]} />
      </VStack>
    </Wrapper>
  );
}

function TypeGroup({ label, sizes, component }) {
  return (
    <HStack space="layout.1" dividers={true}>
      <Heading children={label} size={100} width={128 - 16} />
      <VStack space="layout.1">
        {sizes.map((size, i) => {
          return React.createElement(
            component,
            { size: size, key: label + i },
            [label + " " + size]
          );
        })}
      </VStack>
    </HStack>
  );
}

function Typography() {
  return (
    <Wrapper py="layout.5" bg="background.0">
      <VStack space="layout.1" dividers={true}>
        <TypeGroup
          label="Heading"
          component={Heading}
          sizes={[900, 800, 700, 600, 500, 400, 300, 200, 100]}
        />
        <TypeGroup
          label="Paragraph"
          component={Paragraph}
          sizes={[600, 500, 400]}
        />
        <TypeGroup label="Text" component={Text} sizes={[500, 400, 300]} />
      </VStack>
    </Wrapper>
  );
}

function ButtonGroup({ label, intent }) {
  const appearances = ["default", "primary", "subtle", "minimal"];

  return (
    <HStack space="layout.1" dividers={true}>
      <Heading children={label} size={100} width={128 - 16} />
      <Inline space="layout.1">
        {appearances.map((appearance, i) => (
          <Button
            children={appearance}
            intent={intent}
            appearance={appearance}
            style={{ textTransform: "capitalize" }}
            key={intent + i}
          />
        ))}
      </Inline>
    </HStack>
  );
}

function Buttons() {
  return (
    <Wrapper py="layout.5" bg="background.0">
      <VStack space="layout.1" dividers={true}>
        <ButtonGroup label="Default" intent="default" />
        <ButtonGroup label="Primary" intent="primary" />
        <ButtonGroup label="Secondary" intent="secondary" />
      </VStack>
    </Wrapper>
  );
}

function DesignSystemPage() {
  return (
    <Box>
      <ColorMode mode="light">
        <Colors />
      </ColorMode>
      <ColorMode mode="dark">
        <Colors />
      </ColorMode>
      <Typography />
      <ColorMode mode="light">
        <Buttons />
      </ColorMode>
      <ColorMode mode="dark">
        <Buttons />
      </ColorMode>
    </Box>
  );
}

export default DesignSystemPage;
